import request from "../customUtil/request"

export async function getListCompany(param) {
  return request("/admin/super/list-store", {
    method: "GET",
    params: param,
  })
}
export async function deleteListCompany(data) {
  return request("/admin/super/delete-store", {
    method: "DELETE",
    data: data,
  })
}

export async function unlockStore(data) {
  return request("/admin/super/activate-store", {
    method: "POST",
    data: data,
  })
}
export async function getListStore(param) {
  return request("/admin/super/customer", {
    method: "GET",
    params: param,
  })
}

export async function changeTextToKatakana(data) {
  return request(`store/convert/sentence`, {
    method: "POST",
    data: data,
  })
}
