const arrayFormat = [
  {
    id: 1,
    name: "生産者・メーカー",
    type: "SELLER",
  },
  {
    id: 2,
    name: "飲食店・小売店",
    type: "BUYER",
  },
  {
    id: 3,
    name: "卸会社",
    type: "SUPPLIER",
  },
]

export default arrayFormat
