import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import React from "react"

export const paginationCustom = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <>
        <Space>
          <Button style={{ borderRadius: "2px" }}>
            <a>
              <DoubleLeftOutlined
                style={{ verticalAlign: "middle", fontSize: "1rem" }}
              />
            </a>
          </Button>
          <Button style={{ borderRadius: "2px" }}>
            <a className="font-semibold">前のページ</a>
          </Button>
        </Space>
      </>
    )
  }

  if (type === "next") {
    return (
      <>
        <Space>
          <Button style={{ borderRadius: "2px" }}>
            <a className="font-semibold">次のページ</a>
          </Button>
          <Button style={{ borderRadius: "2px" }}>
            <a>
              <DoubleRightOutlined
                style={{ verticalAlign: "middle", fontSize: "1rem" }}
              />
            </a>
          </Button>
        </Space>
      </>
    )
  }

  return originalElement
}
