import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import "./styles.scss"
import SuperSalesCommissionSettingCompany from "../../domain/superSalesCommissionSettingCompany"

const index = ({ location }) => {
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout
        selectedPage={
          <SuperSalesCommissionSettingCompany location={location} />
        }
      />
    </div>
  )
}

export default index
